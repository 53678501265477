import { useEffect } from 'react';
import _debounce from 'lodash/debounce';
import UAParser from 'ua-parser-js';

const r = new UAParser().getResult();

export const useSetKeyboardInsetHeightVar = () => {
  const systemName = r?.os?.name;

  useEffect(() => {
    if (systemName === 'iOS') {
      const handleResize = _debounce(() => {
        document.documentElement.style.setProperty(
          "--keyboard-inset-height",
          `${window.innerHeight - Math.round(window.visualViewport?.height ?? 0)}px`
        );

        window.scroll({ top: 0, behavior: 'smooth' });
      }, 100);

      window.visualViewport?.addEventListener('resize', handleResize);

      return () => {
        window.visualViewport?.removeEventListener('resize', handleResize);
      }
    }
  }, [systemName])
}